<template>
  <div class="about">
    <section id="top">
      <Header id="header" :scrolled="true" />
    </section>

    <section id="moreInfo" class="d-flex justify-content-center">
      <div class="px-3 py-4 mt-5 rounded shadow bg-white col-lg-9">
        <div class="d-flex justify-content-center">
          <h3 class="mt-4 text-center mx-auto">Get In Touch</h3>
        </div>

        <div class="d-block d-lg-flex border-0 justify-content-between px-1 px-lg-3 my-5">
          <div class="col-12 col-lg-6 px-2 px-md-5">
            <p class="mb-3 fs-5">Leave us a message</p>

            <div>
              <div class="">
                <label for="fullName">
                  <span class="text-danger">*</span>
                  Name
                </label>
                <input id="fullName" v-model="form.fullName" class="form-control" type="text" placeholder="Full Name" />
              </div>

              <div class="mt-4">
                <label for="email">
                  <span class="text-danger">*</span>
                  Email Address
                </label>
                <input id="email" v-model="form.email" class="form-control" type="email" placeholder="Email address" />
                <small v-if="!isEmailValid" class="text-danger fw-bold">
                  Invalid Email, please enter a valid email address!
                </small>
              </div>

              <div class="mt-4">
                <label for="message">
                  <span class="text-danger">*</span>
                  Message
                </label>
                <textarea name="message" v-model="form.message" id="message" cols="30" rows="6"
                  class="form-control"></textarea>
              </div>

              <button :disabled="!sendReady || sending" @click="sendMessage" class="btn mt-4 w-100">
                <span>Send</span>
                <span v-if="sending" class="spinner-border spinner-border-sm text-light ms-3" role="status"></span>
              </button>
            </div>
          </div>

          <div class="col-12 col-lg-6 mt-5 mt-lg-0">
            <ul>
              <li class="d-flex">
                <p class="me-3 mt-1 fs-5">
                  <i class="fas fa-map-marker-alt"></i>
                </p>
                <p>
                  Appmart Software Development House, Drive 2, House 46, Prince
                  & Princess Estate, Duboyi District, FCT Abuja.
                </p>
              </li>

              <li class="d-flex">
                <p class="me-3 fs-5">
                  <i class="fas fa-phone-alt"></i>
                </p>
                <p> 09-7000610, 09-2901257, 09025579505</p>
              </li>

              <li class="d-flex">
                <p class="me-3 fs-5">
                  <i class="fas fa-envelope"></i>
                </p>
                <p>
                  <a href="mailto:info@appmartgroup.com">
                    info@appmartgroup.com
                  </a>
                </p>
              </li>

              <li class="d-flex col-12 col-lg-5">
                <p class="me-4 fs-3">
                  <a href="https://www.instagram.com/appmartng/" target="_blank" title="Appmart Instagram">
                    <i class="fab fa-instagram-square"></i>
                  </a>
                </p>
                <p class="me-4 fs-3">
                  <a href="https://ng.linkedin.com/company/appmart-integrated" target="_blank" title="Appmart LinkedIn">
                    <i class="fab fa-linkedin"></i>
                  </a>
                </p>
                <p class="me-4 fs-3">
                  <a href="https://www.facebook.com/appmartgroup" target="_blank" title="Appmart Facebook">
                    <i class="fab fa-facebook"></i>
                  </a>
                </p>
                <p class="me-4 fs-3">
                  <a target="_blank" title="Appmart Twitter" href="https://twitter.com/appmartgroup">
                    <i class="fab fa-twitter"></i>
                  </a>
                </p>
              </li>
            </ul>

            <div class="map-side">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe width="100%" height="300" id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Appmart%20Integrated%20office&t=&z=11&ie=UTF8&iwloc=&output=embed"
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
import { guestAxios } from "../services/AxiosInstance";
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "ContactUs",

  components: {
    Header,
    Footer,
  },

  data() {
    return {
      scrollHeight: 0,
      form: {
        email: null,
        fullName: null,
        message: null,
      },
      sending: false,
      isEmailValid: true,
    };
  },

  mounted() {
    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  },

  computed: {
    emailReady() {
      return this.validEmail(this.form.email);
    },

    sendReady() {
      return this.emailReady && this.form.fullName && this.form.message;
    },
  },

  methods: {
    validEmail(email) {
      const regPtn =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regPtn.test(email);
    },

    async sendMessage() {
      try {
        this.sending = true;
        const body = {
          ...this.form,
        };

        const api = "auth/send-message";
        const res = await guestAxios.post(api, body);

        this.sending = false;
        if (!res) {
          return;
        }

        const { data } = res;

        // clear the form
        this.form = {
          email: null,
          fullName: null,
          message: null,
        };

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        console.log(err);
        this.sending = false;
      }
    },
  },
};
</script>
<style scoped>
.about {
  height: 100%;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.about::before {
  content: "";
  background-image: url("../assets/images/bg_curve.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  /* left: 0; */
  right: 0;
  bottom: 0;
  /* top: 0; */
  z-index: -1;
  pointer-events: none;
  opacity: 0.5;
}

a {
  text-decoration: none;
  color: inherit;
}

h3 {
  color: #03435f;
  border-bottom: 1px solid #03435f;
}

p {
  color: #0f001a;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

input {
  height: 55px;
  color: #0b2238;
  background: #ffffff;
}

input:hover,
input:focus,
textarea:focus {
  box-shadow: none !important;
  border-color: #165df5;
}

input::placeholder {
  color: #6c7a87 !important;
}

button {
  background: #03435f;
  color: #ffffff;
  padding: 1rem;
}

button:hover {
  color: #ffffff;
}

.fs-7 {
  font-size: 13px !important;
}

.shadow {
  box-shadow: none !important;
}

.map-side {
  width: 100%;
  overflow-x: scroll;
}

/* FOR MAP */
.mapouter {
  position: relative;
  text-align: right;
  height: 300px;
  width: 100%;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 300px;
  width: 100%;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  #moreInfo {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
