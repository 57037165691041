<template>
  <div class="about">
    <section id="top">
      <Header id="header" :scrolled="true" />
    </section>

    <section id="moreInfo" class="">
      <Whoweare />

      <TheTeam />
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Whoweare from "@/components/about/Whoweare.vue";
import TheTeam from "@/components/about/TheTeam.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "About",

  components: {
    Header,
    Whoweare,
    TheTeam,
    Footer,
  },

  data() {
    return {
      scrollHeight: 0,
    };
  },

  mounted() {
    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  },
};
</script>
<style scoped>
.about {
  height: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.about::before {
  content: "";
  background-image: url("../assets/images/line-cc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  opacity: 0.03;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #moreInfo {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
