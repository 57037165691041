<template>
  <div class="px-3 mt-5">
    <div class="d-block text-center px-3 mt-5">
      <h4>The Team</h4>
      <h6 class="mt-4">Who We Are</h6>

      <div class="mt-5 d-flex flex-wrap justify-content-between">
        <div v-for="(p, i) in peoples" :key="i" class="team-box mb-5">
          <img
            width="150"
            height="150"
            class="rounded-circle mx-auto mb-2"
            :src="require(`@/assets/peoples/${p.image}`)"
            alt="team"
          />
          <p class="text-center text-wrap mb-0 font-weight-bold">
            {{ p.name }}
          </p>
          <p class="text-center mb-0">{{ p.role }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import peoples from "../../assets/data/people.json";
export default {
  name: "TheTeam",

  data() {
    return {
      peoples,
    };
  },
};
</script>
<style scoped>
h2 {
  font-weight: bold;
  color: #0b2238;
  line-height: 38px;
  font-size: 1.875rem;
}
h6 {
  color: #0b2238;
  font-size: 1rem;
}
.team-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team-box p {
  width: 140px;
}

/* X-Small devices (portrait phones, less than 376px) */
@media (max-width: 376px) {
  .team-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
  .team-box p {
    width: 160px;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .team-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
  }
  .team-box p {
    width: auto;
  }
}
</style>
